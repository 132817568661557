<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card class="ma-0 mb-0">
      <v-card-title class="dialog_title">
        <span> {{ title }}</span>
      </v-card-title>
      <v-card-text class="mb-0 mb-0">
        <v-container fluid>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row align="center">
              <v-col cols="12" class="ma-0 pb-0">
                <v-alert dense outlined type="error" v-if="errMsg">{{
                  errMsg
                }}</v-alert>
                <v-text-field
                  dense
                  label="Email"
                  outlined
                  @change="validate"
                  :rules="emailRules"
                  v-model="email"
                  v-on:keydown.enter.prevent="action(email)"
                  class="mt-8 mb-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider class="ma-0"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="dialog = false">Cancel</v-btn>
        <v-btn
          :disabled="!valid || email.length == 0"
          color="blue darken-1"
          text
          @click="action(email)"
          >{{ actionTitle || "Save" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "email-input",
  props: {
    open: Boolean,
    title: String,
    actionTitle: String,
    action: Function,
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
  computed: {
    dialog: {
      set(val) {
        if (!val) this.$emit("close");
      },
      get() {
        return this.open;
      },
    },
  },
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      errMsg: "",
    };
  },
};
</script>
