<template>
  <v-overlay :value="true" absolute>
    <v-progress-circular
      :width="5"
      color="white"
      size="70"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "app-loading",
};
</script>
