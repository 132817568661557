import { auth, provider } from "./firebase";

export const getUser = () => {
  return auth.currentUser;
};
export const logout = () => {
  auth.signOut();
  location.reload();
};
export const login = () => {
  auth.signInWithRedirect(provider);
};
export const finishLoginWithLink = () => {
  if (auth.isSignInWithEmailLink(window.location.href)) {
    var email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    auth
      .signInWithEmailLink(email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        if (result.user && result.user.email) {
          window.location.href = `${window.location.origin}?reload`;
        }
      })
      .catch((error) => {
        console.error("finishLoginWithLink error", error);
      });
  }
};
export const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
export const loginWithLink = (email, callback) => {
  const actionCodeSettings = {
    url: window.location.origin + "?loginWithLink=yes",
    handleCodeInApp: true,
  };

  auth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      if (callback) callback();
      window.localStorage.setItem("emailForSignIn", email);
    })
    .catch((error) => {
      console.error("loginWithLink error", error);
    });
};
