import Vue from "vue";
import Vuex from "vuex";
import { auth, db } from "./firebase";
import router from "./router";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    user: {},
    loading: true,
    breadcrumbs: [],
    site: "",
    isAdmin: false,
    canAccess: false,
    authLoading: true,
    availabilities: [
      { id: "1", name: "Available" },
      { id: "2", name: "Sick Leave" },
      { id: "3", name: "Long Term Leave" },
      { id: "4", name: "Scheduled Leave" },
      { id: "5", name: "Restricted Duties" },
    ],
  },
  mutations: {
    isAdmin(state, val) {
      state.isAdmin = val;
    },
    authLoading(state, val) {
      state.authLoading = val;
    },
    canAccess(state, val) {
      state.canAccess = val;
    },
    breadcrumbs(state, val) {
      state.breadcrumbs = val;
    },
    site(state, val) {
      state.site = val;
    },
    loading(state, val) {
      state.loading = val;
    },
    setUser(state, val) {
      state.user = val;
    },
  },
});

auth.onAuthStateChanged(async (fbUser) => {
  store.commit("loading", false);
  setTimeout(() => {
    store.commit("authLoading", false);
  }, 500);
  if (document.location.pathname.includes("/tv")) {
    store.commit("isAdmin", false);
    store.commit("canAccess", true);
    return;
  }

  if (fbUser) {
    const user = { ...fbUser };
    if (!user.email) {
      for (const provider of user.providerData) {
        if (provider.email) {
          user.email = provider.email;
          continue;
        }
      }
    }
    const userDoc = await db.collection("claims").doc(user.email).get();
    const claims = userDoc.data();
    if (claims.admin) store.commit("isAdmin", true);
    if (claims.site) store.commit("site", claims.site);
    if (claims.site && claims.site != "") store.commit("canAccess", true);
    store.commit("setUser", user);
  } else {
    store.commit("canAccess", false);
    const pathname = document.location.pathname;
    const current = router.resolve(pathname);
    if (current?.route?.meta?.auth === false) return;
  }
});
