<template>
  <v-app>
    <navBar v-if="showNavBar" :drawer="drawer" @drawer="setDrawer" />

    <appBar v-if="showNavBar" @drawer="drawer = !drawer" />

    <v-main :class="showNavBar" class="grey lighten-5">
      <v-divider class="ma-0 pa-0" />
      <v-container
        :fluid="!showNavBar"
        :class="showNavBar ? 'white mt-2 rounded-lg' : ''"
      >
        <router-view v-if="!authLoading && canAccess" />
        <loginPage v-if="!user.uid && !authLoading" />
        <accessDenied
          v-if="!canAccess && !authLoading && user.uid"
          :user="user"
        />
      </v-container>
    </v-main>
    <appLoading v-if="loading" />
  </v-app>
</template>

<script>
import appBar from "@/components/appBar.vue";
import navBar from "@/components/navBar.vue";
import accessDenied from "@/components/accessDenied.vue";
import appLoading from "@/components/appLoading.vue";
import loginPage from "./views/login.vue";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    accessDenied,
    appBar,
    navBar,
    appLoading,
    loginPage,
  },
  computed: {
    ...mapState(["loading", "authLoading", "canAccess", "user"]),
    showNavBar() {
      if (this.authLoading) return false;
      if (!this.canAccess && !this.authLoading) return false;
      return !this.$route.meta.hideNavbar;
    },
  },

  methods: {
    setDrawer(val) {
      this.drawer = val;
    },
  },
  data: () => ({
    drawer: true,
  }),
};
</script>
<style>
.dialog_title {
  background-color: #f8f9fe;
}
.dialog_title span {
  font-size: 16px !important;
  color: #607d8b;
}
</style>
