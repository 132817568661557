<template>
  <v-navigation-drawer v-model="localDrawer" app>
    <v-list>
      <v-list-item class="px-2">
        <v-img src="../assets/Australia_Post.svg" class="rounded-lg"></v-img>
      </v-list-item>
      <v-list-item
        v-for="item in userLinks"
        :key="item.icon"
        link
        @click="item.action"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    drawer: Boolean,
  },
  computed: {
    ...mapState(["isAdmin"]),
    userLinks() {
      const links = this.links;
      if (this.isAdmin) {
        links.push({
          icon: "mdi-account-key-outline",
          text: "Users",
          action: () => {
            this.$router.push({ path: "/users" });
          },
        });
      }
      return links;
    },
    localDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        this.$emit("drawer", val);
      },
    },
  },
  data() {
    return {
      links: [
        {
          icon: "mdi-television",
          text: "Screens",
          action: () => {
            this.$router.push({ path: "/screens" });
          },
        },
        {
          icon: "mdi-account-multiple",
          text: "Members",
          action: () => {
            this.$router.push({ path: "/members" });
          },
        },
        {
          icon: "mdi-account-group-outline",
          text: "Teams",
          action: () => {
            this.$router.push({ path: "/teams" });
          },
        },
      ],
    };
  },
};
</script>
