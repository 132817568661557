<template>
  <div>
    <v-app-bar app color="white" :class="fontColor" fixed flat dense>
      <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="">
        <v-breadcrumbs
          class="pl-0"
          v-if="breadcrumbs.length"
          :items="breadcrumbs"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <span v-else> {{ $route.meta.pageName }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list class="ma-0 mp-2 pa-0">
          <v-list-item disabled style="color: black !important">
            {{ user.email }}
          </v-list-item>
          <v-divider class="ma-0 pa-0" />
          <v-list-item dense class="ma-0 pa-0">
            <v-btn @click="logout" text block tile class="ma-0 pa-0">
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { logout } from "../user";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user", "breadcrumbs"]),
  },
  props: {
    drawer: Boolean,
  },
  methods: {
    toggleDrawer() {
      this.$emit("drawer", !this.drawer);
    },
    logout() {
      logout();
    },
  },
  data() {
    return {
      fontColor: "gray--text darken-4",
    };
  },
};
</script>
