import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyBKF_Vvb7ZMTBZ3s2GmqrW-tPkRiZegTpE",
  authDomain: "auspost-7a003.firebaseapp.com",
  projectId: "auspost-7a003",
  storageBucket: "auspost-7a003.appspot.com",
  messagingSenderId: "746524876421",
  appId: "1:746524876421:web:1fb28d2f3fa9dedb4bd3ec",
};

export const app = firebase.initializeApp(firebaseConfig);
const appCheck = firebase.appCheck();
appCheck.activate("3461D157-BED6-4CE3-AE08-DCE8EA53986D", true);
// export variables
export const provider = new firebase.auth.OAuthProvider("microsoft.com");
export const auth = firebase.auth();
export const db = app.firestore();
console.log("location.hostname", location.hostname);
if (location.hostname == "localhost") {
  db.useEmulator("localhost", 5002);
  //auth.useEmulator("http://localhost:5004");
}
