<template>
  <v-container class="white">
    <v-row style="height: 200px">
      <v-col align-self="center">
        <v-card class="pa-2 text-center" flat tile>
          Acess denied for email:
          {{ user.email }}
          <br />
          <v-btn @click="logout" class="mt-2" text
            >Sign in with a different email</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { logout } from "../user";
export default {
  name: "access-denied",
  props: {
    user: {},
  },
  methods: {
    logout() {
      logout();
    },
  },
};
</script>
