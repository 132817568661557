import Vue from "vue";
import VueRouter from "vue-router";
import { getUser } from "../user";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "../views/home.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
    meta: {
      auth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/:site/screen1",
    name: "Screen1",
    component: () =>
      import(/* webpackChunkName: "screen-1" */ "../views/screen-1.vue"),
    meta: {
      auth: true,
      hideNavbar: true,
    },
  },
  {
    path: "/:site/screen2",
    name: "Screen2",
    component: () =>
      import(/* webpackChunkName: "screen-2" */ "../views/screen-2.vue"),
    meta: {
      auth: true,
      hideNavbar: true,
    },
  },
  {
    path: "/:site/screen1/tv",
    name: "Screen1TV",
    component: () =>
      import(/* webpackChunkName: "tv" */ "../views/screen-1.vue"),
    meta: {
      auth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/:site/screen2/tv",
    name: "Screen2TV",
    component: () =>
      import(/* webpackChunkName: "tv" */ "../views/screen-2.vue"),
    meta: {
      auth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/:site/screen3/tv",
    name: "Screen3TV",
    component: () =>
      import(/* webpackChunkName: "tv" */ "../views/screen-3.vue"),
    meta: {
      auth: false,
      hideNavbar: true,
    },
  },
  {
    path: "/screens",
    name: "screens",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/screens.vue"),
    meta: {
      auth: true,
      pageName: "Screens",
    },
  },
  {
    path: "/members",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/members.vue"),
    meta: {
      pageName: "Members",
    },
  },
  {
    path: "/teams",
    name: "teams",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/teams.vue"),
    meta: {
      pageName: "Teams",
    },
  },
  {
    path: "/teams/:id/:team/rnds",
    name: "RNDs",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/rnds.vue"),
    meta: {
      pageName: "RNDs",
    },
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/users.vue"),
    meta: {
      pageName: "Users",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(function (to, from, next) {
  if (to.meta?.auth) to.meta.user = getUser();
  next();
});
export default router;
