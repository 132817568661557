<template>
  <v-container fluid class="grey lighten-5 text-center">
    <v-row>
      <v-col>
        <v-card class="text-center" flat :height="height">
          <v-img
            max-width="300px"
            src="../assets/Australia_Post.svg"
            class="rounded-lg"
          ></v-img>
          <v-card-text
            ><v-btn outlined :loading="signing" text @click="login"
              >Sign in using SSO</v-btn
            >
          </v-card-text>
          <v-card-text
            ><v-btn outlined text @click="loginWithLinkDialog = true"
              >Sign in using email link</v-btn
            >
          </v-card-text>
          <v-card-text v-if="showSignInLinkMsg" class="title orange--text">
            We have sent you a link to your inbox. Click on the link to sign in
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <emailInput
      :open="loginWithLinkDialog"
      :action="loginWithLink"
      actionTitle="Send Link"
      v-if="loginWithLinkDialog"
      @close="loginWithLinkDialog = false"
      title="Sign in with Email Link"
    />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  login,
  getUser,
  loginWithLink,
  finishLoginWithLink,
  getUrlParameter,
} from "../user";
import emailInput from "../components/emailInput.vue";
export default {
  name: "home-page",
  components: {
    emailInput,
  },
  data() {
    return {
      loginWithLinkDialog: false,
      signing: false,
      showSignInLinkMsg: false,
    };
  },
  methods: {
    loginLinkCallBack() {
      this.showSignInLinkMsg = true;
      this.$store.commit("loading", false);
    },
    loginWithLink(email) {
      this.loginWithLinkDialog = false;
      this.$store.commit("loading", true);
      loginWithLink(email, this.loginLinkCallBack);
    },
    login() {
      this.signing = true;
      login();
    },
  },
  mounted() {
    const user = getUser();
    if (user && user.email) {
      this.$router.push("/");
    }
    if (getUrlParameter("loginWithLink") == "yes") {
      this.$store.commit("loading", true);
      finishLoginWithLink();
    }
  },
  computed: {
    ...mapState(["loading"]),
    height() {
      return document.body.clientHeight - 450;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
