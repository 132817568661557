// import "babel-polyfill";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import { firestorePlugin } from "vuefire";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";
import * as admin from "./admin";
Vue.use(firestorePlugin);
Vue.config.productionTip = false;
Vue.prototype.$admin = admin;
new Vue({
  vuetify,
  router,
  store: store,
  render: (h) => h(App),
}).$mount("#app");
